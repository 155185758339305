import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As two passionate trail bikers We have been developing Trailguide since 2013,
and we always wanted it to be free for other trail bikers to discover and
enjoy trails around the world, and around the corner.`}</p>
    <br />
    <h4>{`Free of charge`}</h4>
    <p>{`Trailguide is still free to use, and we have enjoyed lots of growth in content and
users over the last couple of years. However, with increased growth, there is
also a big increase in the costs related to the digital services we need to
run it as fast and stable as we want to.`}</p>
    <br />
    <h4>{`Digital costs`}</h4>
    <p>{`There are costs related to the server hosting the software and storing all
the data. There are costs related to the map server where our map and the
overlays are rendered and stored. There are costs related to the other maps
we use. There are costs related to automatic translations, location services,
error reporting systems, domain registraions and so on.`}</p>
    <br />
We strive to build and use our own software as much as we can, but like any
other manufacturer or service provider, we need to rely on these services.
All this adds up to around 7.000kr (700€) per month as of the summer of 2020.
    <br />
    <br />
    <h4>{`PayPal, Vipps or Patreon`}</h4>
    <p>{`You can make one-time donations by using PayPal.`}</p>
    <a href="https://paypal.me/trailguide">
  <Image src="news/paypal.jpg" className="w-48 mt-8 shadow rounded px-2 py-1" mdxType="Image" />
    </a>
    <br />
    <p>{`If you have a Norwegian bank account you can donate a one-time amount by
using Vipps`}</p>
    <Image src="news/vipps.png" className="w-32 mt-8" mdxType="Image" />
    <div className="w-32 text-center text-4xl text-orange-900 font-bold">571734</div>
    <br />
    <p>{`You can also donate a small monthly amount by becoming a Trailguide Patreon`}</p>
    <a href="https://www.patreon.com/trailguideapp?fan_landing=true">
  <Image src="news/patreon.png" className="w-48 mt-8" mdxType="Image" />
    </a>
    <br />
    <Image src="news/peter_bjorn_gunther.jpg" mdxType="Image" />
    <p className="text-xs mb-16" align="center">
  Peter Seidl (GIS Engineer, Marketing, and Sales)
  <br />
  Bjørn Jarle Kvande (Founder and Software Developer)
  <br />
  Güntherwagen (Marketing, Transport, and Lodging)
  <br />
  Foto: Gry Svantesvold
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      